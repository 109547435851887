import React from 'react';

  const Space = () =>{
    return (
      <>
      <div className="py-4 py-lg-5"/>
      </>
  )};

export default Space;
