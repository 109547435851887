import React, { useState } from 'react';

const SupportAgent = () => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div id='support-agent'>
      {/* Icon to trigger the support agent */}
      <div className="support-icon" onClick={toggleVisibility}>
        💬 {/* Replace with an actual icon from your icon library */}
      </div>

      {/* Support Agent Popup */}
      {isVisible && (
        <div className="support-popup">
          <iframe
            src="https://console.dialogflow.com/api-client/demo/embedded/af55dcb6-5228-43d2-87d9-2273e20f0f0b"
            frameBorder="0"
            style={{
              width: '100%',
              height: '100%',
            }}
            title="Support Agent"
          ></iframe>
          {/* Close Button */}
          <button className="close-button" onClick={toggleVisibility}>
            ✖
          </button>
        </div>
      )}
    </div>
  );
};

export default SupportAgent;
