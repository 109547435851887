import React from "react";
import { Check, CheckLg, Quote } from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';

const YearlyPricingLanding = () => {
  
  return (
    <>
    <div className="row justify-content-center gy-4 bg-grey px-md-5">
          <div className="col-10 col-md-8 col-lg-3 mx-md-4 py-3 pt-md-3 pb-md-0  bg-white shadow-ss-1 rounded-4 text-center">
          <div className="">
            <div className="col-12 pb-3 pb-md-0">
            <h4 className="fs-ss-22 fw-800 text-dark lh-sm text-center pt-3">
            Standard
            </h4>
              <div className="fs-ss-56 fw-ss-700 text-ss-primary">
              <div className="fs-ss-56 line-through fs-ss-35 fw-500"> $1,548 </div>
              <span className="">$1,368</span>
              </div>
              <div className="pb-4 fs-ss-24 fw-ss-800">Per Year</div>
              <div className="pb-md-5 px-3 fs-ss-13 lh-sm text-start fw-500">
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Unlimited Dictations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Real-time patient-doctor conversations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Customized Templates </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> ICD-10 Recommendations </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Monthly Access </div>
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Priority Support </div>
              <div className=""> <CheckLg className='text-ss-primary me-2'/> Perfect for Individual Practitioners </div>
              </div>
              </div>
              </div>
              </div>
            
          <div className="col-10 col-md-8 col-lg-3 text-center py-3 pt-md-3 pb-md-0  bg-white shadow-ss-1 rounded-4">
          <div className="">
            <div className="col-12 pb-3 pb-md-0">
            <h4 className="fs-ss-22 fw-800 text-dark lh-sm text-center pt-3">
            Premium
            </h4>
              <div className="fs-ss-56 fw-ss-700 text-ss-primary">
              <div className="fs-ss-56 line-through fs-ss-35 fw-500"> $2,148 </div>
                <span className="">$1,908</span>
              </div>
              <div className="pb-4 fs-ss-24 fw-ss-800">Per Year</div>
              <div className="pb-md-5 px-3 fs-ss-13 lh-sm text-start fw-500">
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Integration with Athena </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Unlimited Dictations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Real-time patient-doctor conversations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Customized Templates </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> ICD-10 Recommendations </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Monthly Access </div>    
              <div className="pb-2 lh-sm"> <CheckLg className='text-ss-primary me-2'/> Priority Support </div>    
              <div className=""> <CheckLg className='text-ss-primary me-2'/> For Clinics with Athena </div>    
              </div>

            </div>
            </div>
            </div>

            <div className='pt-2 text-center pt-md-4'>
                          <a className='me-2 me-md-4' href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
                          <button className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Try for Free
                              </button>
                          </a>
                          <Link to="/book-demo">
                          <button className='btn fs-ss-20 btn-primary-gradient shadow-ss-1 rounded-5 banner-btn-padding fw-700 '>
                                  Book a Demo
                              </button>
                          </Link>
                          </div>
                          </div>
    </>
  );
};

export default YearlyPricingLanding;
