import React from 'react';
import { Link, useLocation, useNavigate } from "react-router-dom"
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';
// Sources
import HeaderLogo from '../assets/images/Home/HeaderLogo.png'
import {PersonFill} from 'react-bootstrap-icons';
import NavBarLink from './NavBarLink';
import NavBarLink2 from './NavBarLink2';

const PageHeaderLanding = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  return (
    <>
    <section className="d-none d-md-block">
    <section className="col-12 col-md-11 mx-auto">
    <Navbar expand="xl" className="" id="website-nav">
        <div className='container-fluid px-0'>
            <Navbar.Brand className='navbar-brand px-lg-5'>
            <Link className="navbar-brand" to="/">
              <img className='header-logo py-2 my-1' src={HeaderLogo} alt="Header Logo" />
            </Link>
            </Navbar.Brand>

                
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-lg`} className='rounded-3 fs-ss-btn mb-menu'/>
            <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-lg`}
            aria-labelledby={`offcanvasNavbarLabel-expand-lg`}
            placement="start"
            className="navbar-offcanvas"
            >
            <Offcanvas.Header closeButton>

                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-lg`}>
                <Link className="navbar-brand" to="/">
                  <img className='header-logo' src={HeaderLogo} alt="Header Logo" />
                </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
        <ul id='landing-page' className='navbar-nav align-items-md-center ms-auto text-poppins'>
          <li className="nav-item">
          <Link to="/book-demo" className={splitLocation [1] === "book-a-demo" ? "nav-link hover-pri fw-600 text-ss-primary" : "nav-link hover-pri fw-500 "}> Book a Demo </Link>
          </li>

          <li className='pe-lg-3 ps-lg-2 mt-2 mt-md-0'>
            <a href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
              <button className='btn shadow-sm btn-primary-gradient rounded-5 py-2 px-4 fw-500 fs-ss-15'>
                Try for Free
              </button>
            </a>
          </li>
        </ul>
        
            </Offcanvas.Body>
            </Navbar.Offcanvas>
            </div>        
            </Navbar>
            </section>
            </section>
    </>
  )
}

export default PageHeaderLanding;