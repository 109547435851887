import React from 'react';
import {Link} from 'react-router-dom'

// Sources
import FooterLogo from '../assets/images/Home/New/FooterLogo.png'
import { EnvelopeFill, Facebook, Instagram, Linkedin, TelephoneFill, Tiktok, Youtube } from 'react-bootstrap-icons';
import moment from 'moment/moment';

const Footer = () => {


  return (
    <>
    <section id='website-responsive' className="container-fluid py-5 bg-footer text-white">
      <div className="row justify-content-evenly align-items-start gy-4 px-lg-5">
        <div className="col-11 col-lg-3">
          <img className='footer-logo' src={FooterLogo} alt="Footer Logo" />
          <div className="pt-0 pt-lg-4 fs-ss-16 text-start lh-para text-poppins">
          Empowering healthcare through AI-powered documentation to transform patient interactions into accurate clinical notes. Empowering healthcare providers to focus on what matters most – exceptional patient care.
          </div>
        </div>
        <div className="col-11 col-lg-3 ps-lg-5 ms-lg-5 pt-lg-5 mt-lg-5">
          <div className='footer-links'>
            <div className="fs-ss-26 pb-lg-3 pb-2 fw-700">
              Quick Links
            </div>
            <div className="fs-ss-17 py-1 fw-500 " >
              <Link className="text-decoration-none text-white" to="/about-us">
              About Us
              </Link>
            </div>
            <div className="fs-ss-17 py-1 fw-500 " >
              <Link className="text-decoration-none text-white" to="/faqs">
              FAQs
              </Link>
            </div>
            <div className="fs-ss-17 py-1 fw-500 " >
              <Link className="text-decoration-none text-white" to="/contact-us">
              Contact Us
              </Link>
            </div>
            <div className="fs-ss-17 py-1 fw-500 " >
              <Link className="text-decoration-none text-white" to="/privacy-policy">
              Privacy Policy
              </Link>
            </div>
            <div className="fs-ss-17 py-1 fw-500 " >
              <Link className="text-decoration-none text-white" to="/terms-and-conditions">
              Terms & Conditions
              </Link>
            </div>
          </div>
        </div>
        <div className="col-11 col-lg-3 ps-lg-5 ms-lg-5 pt-lg-5 mt-lg-5">
        <div className="fs-ss-26 pb-lg-4 pb-2 fw-700">
              Follow Us 
        </div>
        <div className='pb-lg-4 pb-3'>
          <a target='_blank' rel="noreferrer" className='text-decoration-none fw-500 text-white fs-ss-17' href="tel:+15715997989">
        <TelephoneFill className='fs-ss-18 text-white me-2'/> +1 571 599 7989
        </a>
        <div className='pt-1' />
        <a target='_blank' rel="noreferrer" className='text-decoration-none fw-500 text-white fs-ss-17' href="mailto:info@scribemedix.com">
        <EnvelopeFill className='fs-ss-18 text-white me-2'/> info@scribemedix.com
        </a>
        </div>
        <div className='pb-lg-4 pb-3'>
          <a target='_blank' rel="noreferrer" aria-label="Visit Scribe Medix Instagram Page" href="https://www.instagram.com/scribemedix/">
        <Instagram className='fs-ss-30 text-white'/>
        </a>
        <span className='pe-3' />
        <a target='_blank' rel="noreferrer" aria-label="Visit Scribe Medix Facebook Page" href="https://www.facebook.com/scribemedix1/">
        <Facebook className='fs-ss-30 text-white'/>
        </a>
        {/* <span className='pe-3' />
        <a target='_blank' rel="noreferrer" href="https://twitter.com/">
        <Twitter className='fs-ss-30 text-white'/>
        </a> */}
        <span className='pe-3' />
        <a target='_blank' rel="noreferrer" aria-label="Visit Scribe Medix Linkedin Page" href="https://www.linkedin.com/company/scribe-medix/">
        <Linkedin className='fs-ss-30 text-white'/>
        </a>
        <span className='pe-3' />
        <a target='_blank' rel="noreferrer" aria-label="Visit Scribe Medix Youtube Page" href="https://www.youtube.com/channel/UCBVHehYjCGQlyAR008nGxhA">
        <Youtube className='fs-ss-30 text-white'/>
        </a>
        <span className='pe-3' />
        <a target='_blank' rel="noreferrer" aria-label="Visit Scribe Medix Tiktok Page" href="https://www.tiktok.com/@scribemedix">
        <Tiktok className='fs-ss-30 text-white'/>
        </a>
        </div>
        {/* <Link to="/request-a-demo">
      <button className='btn btn-pri-2 shadow-sm rounded-5 py-0 px-4 fw-400 fs-ss-20'>
      Try for Free
      </button>
      </Link> */}
            <a href={`${process.env.REACT_APP_PORTAL_URL}/signup`}>
            <button className='footer-btn btn fs-ss-btn-demo footer-btn text-white bg-ss-primary shadow-sm rounded-5 py-1 py-0 px-3 px-lg-4 fw-500 '>
              Try for Free
            </button>
            </a>
        </div>
      </div>
    </section>
    <section className='bg-ss-primary py-1 py-lg-2 px-5'>
      <div className="text-center fs-ss-copyright text-white">
      Copyright © by Scribe Medix <span> {
        moment().format('YYYY')
        } </span>. Powered by <a className='simple-text' target='_blank' rel="noreferrer" href="https://simplesolutionz.org/">  Simple Solutionz </a>
      </div>
    </section>
    </>
  );
}

export default Footer;
