import React, { Component } from "react";
import Slider from "react-slick";
import Img1 from '../assets/images/Img1.png';
import Img2 from '../assets/images/Img2.png';
import Img3 from '../assets/images/Img3.png';
import Img4 from '../assets/images/Img4.png';

export default class Responsive extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      autoplay: true,
      autoplaySpeed: 8000,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
          }
        }
      ]
    };
    return (
    <div id="slick-testimonials" className="bg-middle-banner-landing-page py-5 px-3 text-center px-0 text-md-start">
    <div id="slick-testimonials" className="col-12 col-md-9 mx-auto">
    <h2 className="fs-ss-32 fw-700 lh-sm pb-4 text-center text-white">
      Client Testimonials
    </h2>
    <Slider {...settings}>
        
      <div className="">
      <div className="">
      <div className="">
      </div>
      <div className="fs-ss-testimonials fw-500 row gy-4 justify-content-evenly align-items-center mx-md-5">
      <div className="col-7 col-md-3">
       <img src={Img4} className="w-100 px-5 px-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 text-white fs-ss-18-landing-page fw-400">
      Using Scribe Medix for my documentation in the office has been a game-changer. It has significantly reduced my work burden, allowing me to spend more time with my patients instead of being stuck in front of the computer. The efficiency it brings means I can get through my day faster and finish earlier, which has been a tremendous benefit. I highly recommend Scribe Medix to any healthcare professional looking to enhance their practice and improve patient care.
      </div>
      </div>  
    </div>

    <div className="py-4 pb-md-0 pt-md-0 text-center text-md-end me-md-5 pe-3 text-white">
    <div className="fs-ss-22 fw-700">
      Dr. Omer Iqbal,
    </div>
    <div className="fs-ss-18-landing-page fw-600 lh-sm">
      IM Clinic
    </div>
    </div>
    </div>

        
    <div className="">
      <div className="">
      <div className="">
      </div>
      <div className="fs-ss-testimonials fw-500 row gy-4 justify-content-evenly align-items-center mx-md-5">
      <div className="col-7 col-md-3">
       <img src={Img2} className="w-100 px-5 px-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 text-white fs-ss-18-landing-page fw-400">
      Scribe Medix is an amazing tool for my everyday practice. I use the dictate function to describe long hospital courses or when a patient has multiple complaints. It saves me so much time. The real-time conversation function is fantastic, especially with sick patients. It records their symptoms, often catching details I might forget. As a new practitioner, my favorite feature is the billing codes for every diagnosis discussed with the patient. This tool is a must-have for any practice and truly saves me so much time! I can't imagine practicing without it now!
      </div>
      </div>          
    </div>

    <div className="py-4 pb-md-0 pt-md-0 text-center text-md-end me-md-5 pe-3 text-white">
    <div className="fs-ss-22 fw-700">
    Michelle Gasque 
    </div>
    <div className="fs-ss-18-landing-page fw-600 lh-sm">
      My Family MD
    </div>
    </div>
    </div>

    <div className="">
      <div className="">
      <div className="">
      </div>
      <div className="fs-ss-testimonials fw-500 row gy-4 justify-content-evenly align-items-center mx-md-5">
      <div className="col-7 col-md-3">
       <img src={Img1} className="w-100 px-5 px-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 text-white fs-ss-18-landing-page fw-400">
      "I was hesitant at first about using a medical scribe service, but I'm really glad I gave it a chance. It has freed up a lot of my time, enabling me to see more patients and focus on their care. The system is professional and consistently deliver quality work. I highly recommend this service!"
      </div>
      </div>         
    </div>

    <div className="py-4 pb-md-0 pt-md-0 text-center text-md-end me-md-5 pe-3 text-white">
    <div className="fs-ss-22 fw-700">
      Dr. Iqbal Saeed,
    </div>
    <div className="fs-ss-18-landing-page fw-600 lh-sm">
      My Family MD
    </div>
    </div>
    </div>

    
    <div className="">
      <div className="">
      <div className="">
      </div>
      <div className="fs-ss-testimonials fw-500 row gy-4 justify-content-evenly align-items-center mx-md-5">
      <div className="col-7 col-md-3">
       <img src={Img3} className="w-100 px-5 px-md-0" alt="" />
      </div>
      <div className="col-11 col-md-9 text-white fs-ss-18-landing-page fw-400">
        
      In this changing world of medicine, Scribe Medix has proven to be a sigh of relief for me. I have been using Scribe Medix for the last two months. I was initially a skeptic worried about the accuracy of the program. To my surprise, Scribe Medix has reduced my work by 70%. Not only does it help me inscribe my patient in real-time, but it also helps me find accurate codes, which are so important in this current medical environment. The program itself is amazing, and on top of that, the support provided by the team is definitely phenomenal. 
      </div>
      </div>         
    </div>

    <div className="py-4 pb-md-0 pt-md-0 text-center text-md-end me-md-5 pe-3 text-white">
    <div className="fs-ss-22 fw-700">
    Virginia Medical Center
    </div>
    </div>
    </div>
      </Slider>
      </div>
      </div>
    );
  }
}